<template>
  <keep-alive include="HomePage">
    <div id="app">
      <transition :name="transitionName">
        <router-view></router-view>
      </transition>
    </div>
  </keep-alive>
</template>
<script>
export default {
  name: 'App',
  data () {
    return {
      transitionName: ''
    }
  },
  methods: {},
  components: {},
  watch: {
    $route (to, from) {
      if (to.meta.index > from.meta.index) this.transitionName = 'slide-left'
      if (to.meta.index < from.meta.index) this.transitionName = 'slide-right'
    }
  }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #000;
}
/* 路由页面跳转交互 */
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 0.3s;
  position: absolute;
  background-color: #000; /* 添加背景色 */
}
.slide-right-enter {
  opacity: 0;
  transform: translate(-100%);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translateX(100%);
}
.slide-left-enter {
  opacity: 0;
  transform: translateX(100%);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translateX(-100%);
}
</style>
