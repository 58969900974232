<template>
  <div class="box">
    <img
      class="back-icon"
      src="@/assets/images/icon_back.png"
      alt=""
      @click="handldBack"
    />
    <h1 class="head">TERMS & CONDITIONS OF USE</h1>
    <p class="list">
      END USER LICENCE AGREEMENT (“EULA”) FOR MINNOW: IMPORTANT – PLEASE READ
      THESE TERMS AND CONDITIONS CAREFULLY BEFORE PROCEEDING TO USE OUR
      SERVICES, AND/OR DOWNLOADING OUR SOFTWARE, AS YOUR AGREEMENT TO THEM
      CONSTITUTES A LEGALLY BINDING AGREEMENT. IT IS YOUR SOLE RESPONSIBILITY TO
      PERIODICALLY REVIEW THIS EULA AND YOUR CONTINUED USE OF THE SITE, AND
      ASSOCIATED SERVICES, CONSTITUTES YOUR ONGOING ACCEPTANCE OF THE TERMS
      CONTAINED HEREINAFTER.
    </p>
    <p class="p">Introduction</p>
    <p class="p">
      The following terms and conditions as updated by us from time to time and
      made available here (these “Conditions”) shall govern the relationship
      between “You” (“Your” also refers to You throughout this EULA) and MINNOW and/or its group companies (together referred to in these Conditions
      as “we”, “us” or “our”) in relation to all use of software licensed by us
      to You, directly or indirectly, and Your play on game client downloaded
      from minnow@poker.com (“MINNOW”).
    </p>
    <p class="p">
      Whether You use MINNOW and/or You are playing any game thereon
      (“Game(s)”) means that You accept these Conditions which are legally
      binding. If You do not agree to accept and be bound by the terms please do
      not open an account or continue to use the website or participate in the
      Games. These Conditions set forth the legally binding terms and conditions
      which are applicable to Your use of the Game or MINNOW, however,
      accessed and/or used, whether via personal computers, mobile devices or
      otherwise. Please be reminded that these Conditions constitute an
      agreement between You and us and define the rights and responsibilities
      that You and we have with respect to the Games and MINNOW. You
      represent and warrant that You have the right, authority and capacity to
      accept these Conditions and to abide by them and that You have fully read
      and understood these Conditions without any impairment in judgment.
    </p>
    <p class="p">
      By visiting , registering on minnow@poker.com (“Website”) and APP /or
      continuing to use the Services You agree to be, and are, bound by:
    </p>

    <p class="list">
      &nbsp;&nbsp; these Conditions; and by accepting these Conditions You also
      accept our;
    </p>

    <p class="list">&nbsp;&nbsp; • Privacy Policy;</p>

    <!-- <p class="list">&nbsp;&nbsp; • Withdrawal Policy;</p>

    <p class="list">
      &nbsp;&nbsp; • Cookie Policy (which sets out how we use personal
      information which You supply to us);
    </p>

    <p class="list">&nbsp;&nbsp; • Security & Ecology Policy;</p>

    <p class="list">&nbsp;&nbsp; • the Game Rules;</p> -->

    <p class="list">
      &nbsp;&nbsp; all such other of our terms and conditions, game rules, rules
      or policies as they relate to any applicable Game, promotions, bonuses,
      special offers or any other aspect of Your use of the Website from time to
      time.
    </p>

    <p class="list">
      &nbsp;&nbsp; In the event of any inconsistency between these Conditions
      and the Game Rules, then, to the extent of the inconsistency, these terms
      and conditions shall prevail.
    </p>
    <!-- 1 -->
    <p class="title">&nbsp;&nbsp;CHANGES TO THE TERMS AND CONDITIONS</p>
    <p class="p">
      1.1 We may need to change the Conditions for a number of reasons,
      including for commercial reasons, to comply with new laws or regulations
      or for customer service reasons. The most up-to-date terms and conditions
      can be accessed here, and the date on which they came into force is noted.
    </p>
    <p class="p">
      1.2 Where We wish to make substantial changes to the Terms and Conditions,
      we will give You as much prior notice of such changes as is reasonably
      practicable via one of the following methods:
    </p>
    <p class="list">
      &nbsp;&nbsp;Email (to the email address You have previously supplied us
      with, if any);
    </p>
    <p class="list">&nbsp;&nbsp;A message in your mailbox of game client;</p>
    <p class="list">&nbsp;&nbsp;A notice on the Website.</p>
    <p class="p">
      1.3 Where we make changes to the Conditions which we wish to notify You of
      directly, we will do so by such method of notification as we may, in our
      discretion, deem to be appropriate.
    </p>
    <p class="p">
      1.4 We may, at our discretion, invite You to accept the new Conditions by
      clicking on “yes” or “I accept”, checking a ‘tick box’ or any other
      similar method of confirmation by You. If You provide us with any such
      confirmation, or if you continue to use the Services and/or Your Account
      after notification under this paragraph 1 (Changes To The Terms And
      Conditions), You shall, from such time, be deemed to have accepted, and be
      bound by, the new Conditions (including any additions, removals,
      substitutions). If any change is unacceptable to You, You may either cease
      using the Services and/or close Your Account by following the steps in
      paragraph 2.6 below.
    </p>
    <p class="p">
      1.5 It remains Your responsibility to periodically check these Conditions
      to ensure you continue to agree with them each time You use the Website
      and the Services.
    </p>
    <!-- 2 -->
    <p class="title">&nbsp;&nbsp;ACCOUNT OPENING AND SECURITY</p>
    <p class="p">
      2.1 In order to register, open an account and participate in the Games,
      You represent and warrant that:
    </p>
    <p class="list">
      &nbsp;&nbsp; • You are at least 18 years of age and meet the minimum age
      requirement in Your local jurisdiction to contract with us, use the
      Website and participate in the Games;
    </p>

    <p class="list">
      &nbsp;&nbsp; • You are not an employee/contractor of (i) MINNOW or (ii)
      any MINNOW group company;
    </p>

    <p class="list">
      &nbsp;&nbsp; • You will abide at all times by these Conditions and any
      other agreements between You and us;
    </p>

    <p class="list">
      &nbsp;&nbsp; • You are legally able to enter into contracts;
    </p>

    <p class="list">
      &nbsp;&nbsp; • You have not been excluded or are not currently
      self-excluded from gambling;
    </p>

    <p class="list">
      &nbsp;&nbsp; • You have not previously had your account closed by us for
      any reason;
    </p>

    <p class="list">
      &nbsp;&nbsp; • You acknowledge that it is your responsibility to ensure
      that You understand and fully comply with any laws or regulations
      applicable in your own country or state.
    </p>
    <p class="p">
      2.2 If You do not meet the eligibility requirements set out above You are
      not authorised to use the Website. We reserve the right to suspend and/or
      close any accounts of any purported customer that does not meet the
      eligibility requirements and void any bets made via such accounts.
    </p>
    <p class="p">
      2.3 You are only permitted to open one account. If You have more than one
      account, You must immediately inform us. Furthermore, only one account for
      each IP/Household is allowed. If You open more than one account, any
      winnings on either account will be forfeited and all accounts may be
      closed. We may block or close Your first account. If we find there more
      than one account registered for Your household (“Duplicate Account”), we
      reserve the right to block or close any or all accounts of that household.
      All transactions made from the Duplicate Account will be made void.
    </p>
    <p class="p">
      2.4 Any returns, winnings or bonuses which You have gained or accrued
      during such time as the Duplicate Account was active will be forfeited by
      You and may be reclaimed by us, and You will return to us on demand any
      such funds which have been withdrawn from the Duplicate Account plus an
      admin fee will also be applied.
    </p>
    <p class="p">
      2.5 You must maintain Your account and keep Your details up-to-date.
    </p>
    <p class="p">
      2.6 If You wish to close Your account You must send a request to do so to
      minnow@poker.com or use the permanent exclusion feature within the
      application. By closing Your Account, You will also forfeit any
      promotional bonuses, prizes or benefits which You may have acquired. In
      the event of closure of Your Account because of gambling addiction or if
      Your account has been closed due to fraud You acknowledge that You are
      prohibited from opening a new account.
    </p>
    <p class="p">
      2.7 The Company will not be liable if You manage to open a new account,
      for such action nor for any direct or indirect consequential damages.
    </p>
    <p class="p">
      2.8 The Company shall have the right to close, at any time, a new account
      opened in breach of the requirement stated herein.
    </p>
    <p class="p">
      2.9You are required to enter all mandatory information requested by us. We
      shall not be liable for incorrect entries, including but not limited to
      data input error.
    </p>
    <p class="p">
      2.10 You must not open an Account if You are located in a jurisdiction in
      which applying for or opening an Account and/or using our Services is
      unlawful or contrary to any applicable regulation “Prohibited
      Jurisdiction”. It is Your responsibility to ensure that this is not the
      case. We reserve the right to immediately suspend Your Account if we
      discover that Your Account has been opened, or is being used, from a
      Prohibited Jurisdiction. We reserve the right to request proof of age
      documentation from any customer at any time.
    </p>
    <p class="p">
      2.11 We do not accept players who reside in a number of countries and
      jurisdictions. As a rule, if a potential player cannot find his/her
      country of residence in the list of countries available during the
      registration process, this means that MINNOW will not be able to accept
      him/her as a player, therefore this person will not be able to access the
      products and services of the MINNOW.
    </p>
    <p class="p">
      2.12 We may suspend or terminate Your Account immediately at our absolute
      discretion if You breach any of Your obligations under these Conditions.
      We reserve the right to claim any remaining balance in Your Account as
      well as the right to claim damages caused by the breach.
    </p>
    <p class="title">&nbsp;&nbsp;YOUR MINNOW ACCOUNT</p>
    <p class="p">
      3.1 Only bets placed from Your account set up through the game client
      downloaded from minnow@poker.com will be accepted for the Game and any
      winnings You make on the Game will be credited to Your account. You may
      facilitate all deposits and withdrawals by accessing the My wallet page.
    </p>
    <p class="p">
      3.2 We reserve the right to refuse or limit (by setting minimum and
      maximum amounts which will be set out in the Game Rules or on the Website)
      any bets and/or payouts at our sole discretion for any reason whatsoever.
      In circumstances a bet is deemed to be or is declared void by us at our
      discretion, any sum deducted from Your Account will respect to that bet
      shall be credited to Your account.
    </p>
    <p class="p">
      3.3 Bets shall only be valid if accepted by our server. Until accepted by
      our server, no communications from You shall be binding on us and all
      information displayed on MINNOW or the Website constitutes an
      invitation to play only. You agree that our records, in the absence of
      manifest error, shall be the final authority in determining the terms of
      any bets You place and the circumstances in which they were made.
    </p>
    <p class="p">
      3.4 Your Account is personal to You. You shall not allow others to use
      Your Account or to accept any prize or participate in any of the Games.
      Any person found to have violated this section be liable for all losses on
      the account but will not be entitled to collect any winnings.
    </p>
    <p class="p">
      3.5 It is Your responsibility to keep Your password private and secure and
      You are solely responsible for the security of Your account information
      and password. You are responsible for any unauthorized use of Your account
      and/or password. In the event that a third party places a bet or is
      thought to have placed a bet from Your account, such bet shall be valid
      and liability for losses on Your account will lie with You, whether or not
      You were aware the third party misappropriated Your account and password
      information.
    </p>
    <p class="p">
      3.6 While we make every effort to ensure that Your data is secure at all
      times, You likewise recognize that there are instances that may damage,
      corrupt, delete or in any way alter Your data that are beyond our control.
      However, we shall ensure that adequate security mechanisms designed to
      protect Personal Data will be used to prevent Personal Data from being
      stolen, misused or abused, and to prevent Personal Data breaches.
    </p>
    <p class="p">
      3.7 It is vital that You ensure that as much as possible Your personal
      information relating to You is kept up-to-date. We reserve the right to
      suspend or terminate Your Account if we suspect this information to be
      inaccurate. We will process information about You in accordance with Your
      Privacy Policy and our Cookie Policy.
    </p>
    <p class="p">
      3.8 MINNOW and any third parties it contracts with in relation to the
      provision of the Services will hold information with respect to Your
      identity, including but not limited to Your name, address and payment
      details, in accordance with our Privacy Policy and Cookie Policy. We rely
      on this information in entering into this agreement with You. You agree to
      indemnify us and hold us harmless against any costs, claims, damages, and
      expenses arising in connection with any falsehood or inaccuracy contained
      in the information You provide to us.
    </p>
    <!-- 4 -->
    <p class="title">&nbsp;&nbsp;CUSTOMER FUND PROTECTION</p>
    <p class="p">
      4.1 Any funds You deposit with the Company will be held in a segregated
      bank account in the name of the Company (“Designated Account”) in
      accordance with the regulations relating to customers’ money. This means
      that all such funds are protected and in the case of insolvency properly
      due funds can be paid to the customer.
    </p>
    <p class="p">
      4.2 The Company keeps a sum of money equivalent to its liabilities to
      customers in the Designated Account which is solely used to hold the
      players’ balance. This Designated Account shall not be used for any
      operational purpose, and the balance of the Designated Account shall be
      audited by third-party auditors to confirm that it matches with the
      player’s total balance to meet any withdrawal request.
    </p>
    <!-- 5 -->
    <p class="title">&nbsp;&nbsp;RESTRICTIONS</p>
    <p class="p">
      5.1 You agree to refrain from using MINNOW or our Website:
    </p>

    <p class="p">&nbsp;&nbsp;in excess of, or beyond, the agreed purpose;</p>
    <p class="p">
      &nbsp;&nbsp; to distribute, sell, license, provide or otherwise make any
      services available to third parties without our consent;
    </p>
    <p class="p">
      &nbsp;&nbsp; to store or transmit material or information that is
      infringing, libellous, defamatory, obscene, fraudulent, false or contrary
      to the ownership or intellectual property rights of any other person or
      otherwise harmful, unlawful or tortious, or in violation of any third
      party privacy rights;
    </p>
    <p class="p">
      &nbsp;&nbsp; in any way that is in violation of any applicable law, rule
      or regulation; or
    </p>
    <p class="p">
      &nbsp;&nbsp;to transmit viruses, malware, or other malicious code.
    </p>

    <p class="p">
      5.2 It is prohibited to modify, reverse-engineer, or otherwise manipulate,
      interfere with or disrupt any of our software used in the Website and/or
      Games. To ensure the integrity of the Website and Games, we reserve the
      right at any time in our sole discretion to block users from certain IP
      addresses from accessing the same tables or tournaments.
    </p>
    <p class="p">
      5.3 We may, at our sole discretion and without any requirement to give
      reasons, exclude any customer from our Services generally and/or from
      receiving selected promotions and offers introduced by us from time to
      time.
    </p>
    <p class="p">
      5.4 We reserve the right to suspend, discontinue, modify, remove, or add
      to the Website and/or any Games in MINNOW at our discretion with
      immediate effect and without any obligation to provide You with notice.
    </p>
    <p class="title">&nbsp;&nbsp;PROMOTIONS</p>
    <p class="p">
      6.1 We may, from time to time, offer bonuses and/or promotions that are
      governed by separate terms and conditions. Any bonuses credited to Your
      account must be used in adherence with such terms and conditions.
    </p>
    <p class="p">
      6.2 In the event and to the extent of a conflict between these Conditions
      and the bonus or promotion terms and conditions, the bonus or
      promotion-specific terms and conditions prevail.
    </p>
    <p class="p">
      6.3 If we believe that You are abusing or attempting to abuse a bonus or
      promotion, or are likely to personally benefit through the abuse of a
      bonus or promotion, we may deny, withhold or Withdraw from You any bonus
      or promotion at our sole discretion. We may also terminate Your access to
      our Website and/or Your Account. In such circumstances, we shall be under
      no obligation to refund to You any remaining balance amount showing in
      Your Account other than Your original deposit amount.
    </p>
    <p class="p">
      6.4 By participating in any Promotion of MINNOW ( the “Promotion”), You
      agree to cooperate in all advertising, marketing, publicity material and
      activities we may choose to utilize. You also agree, if requested, (a) to
      wear any MINNOW-branded clothing or patches provided; and (b) to sign,
      if requested, an irrevocable release form allowing us to use your
      information with respect to the Promotion.
    </p>
    <p class="p">
      6.5 In any event sponsored by MINNOW, You agree not to wear or expose
      third-party branding or advertising for any company which could be deemed
      as a MINNOW competitor. If you breach such a requirement, we reserve
      the right to revoke any prize awarded to you.
    </p>
    <p class="p">
      6.6 With respect to Your participation in any Promotion of MINNOW, You
      confirm that the submitted materials will be Your own original work and
      such materials will not infringe on the intellectual property rights or
      other rights of any person or organization.
    </p>
    <!-- 7 -->
    <p class="title">
      &nbsp;&nbsp;SUSPENSION, RESTRICTION OR TERMINATION OF YOUR ACCOUNT
    </p>
    <p class="p">
      7.1 Without restricting our ability to rely on other remedies that may be
      available to us, we may suspend or terminate Your Account or cancel any
      bets placed by You, remove any bonus monies in Your Account and/or take
      any other action we deem appropriate in our absolute discretion if:
    </p>
    <p class="p">
      &nbsp;&nbsp;We suspect that You are engaging in illegal or fraudulent
      activity while using MINNOW (including circumstances where we suspect
      You have attempted to manipulate the operation of MINNOW or the normal
      play of Games thereon or have been involved in player collusion);
    </p>
    <p class="p">
      &nbsp;&nbsp;We suspect You are in breach of any of these Conditions or the
      Game Rules;
    </p>
    <p class="p">
      &nbsp;&nbsp;We suspect You are acting in a manner that is detrimental to
      the conduct of our business; or
    </p>
    <p class="p">&nbsp;&nbsp;We are required to do so by law.</p>
    <p class="p">
      7.2 Your sole remedy in the event of termination of Your Account by us for
      any reason shall be the reimbursement of any undisputed Account balance
      You may then have, subject to You having complied with these Conditions to
      the fullest extent permitted by law. We shall have no further liability to
      You whatsoever.
    </p>
    <p class="p">
      7.3 Further restrictions that may apply to Your Account in the event of
      system, communications or technological errors or failures are set out in
      paragraphs 16 and 17 of these Conditions.
    </p>
    <!-- 8 -->
    <p class="title">&nbsp;&nbsp;SECURITY CHECKS</p>
    <p class="p">
      8.1 We are committed to providing our customers with an enjoyable poker
      experience in a safe and secure environment, and we operate security
      procedures and checks to protect our customers and MINNOW from fraud
      and collusion. Any kind of BOT or Artificial Intelligence is strictly
      prohibited on MINNOW. Occasionally, as part of this process, we will
      wish to make Checks on Accounts and plays in the Game(s) and this may
      result in the suspension of the use of Accounts whilst these checks are
      carried out, as well as requests for information from customers. We
      believe it is in the best interests of our customers and ourselves that we
      operate this process and it is a condition of participation that all our
      customers acknowledge this and cooperate with us in this respect.
    </p>
    <p class="p">
      8.2 Players can play on MINNOW only upon acceptance of the Security &
      Ecology Agreement. Security & Ecology Agreement includes all the details
      of the security checks that we perform to make the environment safe and
      fair for all players.
    </p>
    <!-- 9 -->
    <p class="title">
      &nbsp;&nbsp;PROHIBITED USES OF THE APP, THE WEBSITE AND SERVICES
    </p>
    <p class="p">9.1 Cheating or Illegal Behaviour</p>
    <p class="p">
      &nbsp;&nbsp;The Game may only be used for lawful purposes and in a lawful
      manner. You agree to comply with all applicable laws, statutes, and
      regulations regarding the Game and any bets placed on or via the Game. All
      bets must be placed through the user interface provided on MINNOW.
    </p>
    <p class="p">
      &nbsp;&nbsp;We reserve the right, in our sole discretion, to prohibit any
      player from participating in any and all Games of MINNOW. We may, where
      situations warrant, freeze the chips, void bets, suspend or terminate the
      account of any player suspected of cheating in any form (which includes,
      without limitation, collusion or the exploitation of faults in software or
      the Website). We further reserve the right to restrict seating and/or to
      prohibit players from playing in a particular Game, including restricting
      two or more players from playing together at the same Game.
    </p>
    <p class="p">
      &nbsp;&nbsp; We reserve the right to void any bet inadvertently accepted
      after the time for placing bets has closed or where the event was resolved
      or at a stage where the player could have any indication of the outcome.
      In these circumstances, the original bet amount will be returned to the
      customer account.
    </p>
    <p class="p">9.2 Circumvention</p>
    <p class="p">
      &nbsp;&nbsp;We have developed and employ sophisticated proprietary
      technology intended to seek out and identify users making fraudulent or
      unlawful use of the Services or our Poker Software. You shall not break
      into, access or attempt to break into or access or otherwise circumvent
      our security measures. If we believe in our sole discretion, that You are
      in breach of this clause, we may terminate Your access to the Services
      immediately and/or have Your account blocked.
    </p>
    <!-- 10 -->
    <p class="title">&nbsp;&nbsp;GAME RULES</p>
    <p class="p">
      10.1 The Game Rules on the Website shall apply to Your participation in a
      Game. In particular, You are not permitted to make a bet in excess of the
      value of chips on the table at the beginning of a hand. You are permitted
      to acquire additional chips only between hands. If You run out of chips
      during a hand, You are considered “all- in”. You may not remove money from
      the table during a playing session. Taking money out of the Game and
      continuing to play is not allowed in a table bets format.
    </p>
    <p class="p">
      10.2 Players must refrain from “table talk” or talking during a hand in a
      way that compromises an opponent’s hand. This applies if You are playing
      in the hand or watching the hand. Repeated abuse of this aspect of player
      behaviour will result in suspension or permanent restriction of play.
    </p>
    <p class="p">
      10.3 Abusive or offensive language or other behaviour considered
      inappropriate by us in the context of the Game will not be tolerated. Bad
      behaviour or offensive language identified by us or brought to our
      attention will be dealt with swiftly and may result in a suspension or
      loss of playing privileges or such other action as may be deemed necessary
      by us at our sole discretion.
    </p>
    <p class="p">
      10.4 You fully accept and agree that the random number generator software
      will determine all outcomes of the Games.
    </p>
    <p class="p">
      10.5 You acknowledge and agree that You shall not interfere, interrupt,
      and attempt to interrupt, or attempt to manipulate the operations of the
      Website or the normal play of any of the Games in MINNOW. Failure to
      comply with the preceding may lead to Your exclusion from the Website and
      to criminal and civil investigations.
    </p>
    <!-- 11 -->
    <p class="title">&nbsp;&nbsp;SERVER CRASH</p>
    <p class="p">
      11.1 If You have chips at a table during a server crash, the Game will be
      cancelled and all of Your chips at the table and in play will be returned
      to Your Account. We, our affiliates, agents and licensors shall not be
      liable for any loss resulting from delays or interruptions to play due to
      failure, breakdown, malfunction, disconnection from or interruption of
      electronic or mechanical equipment, of telephone, internet connection or
      other communications facilities, or due to any denial of service (DDOS)
      attack or other malevolent external interferences with our site or any
      other causes over which we or they have no direct control.
    </p>
    <p class="p">
      11.2 In the event of systems, technological or communications failure or
      errors relating to the generation of any result, bet settlement or any
      other element of the Game or the Website, we will not be liable to You
      because of any such errors and we reserve the right to void all related
      bets and plays on the Game in question.
    </p>
    <!-- 12 -->
    <p class="title">&nbsp;&nbsp;INTELLECTUAL PROPERTY RIGHTS</p>
    <p class="p">
      12. These Conditions confer only the right to use MINNOW, the Website,
      and the Games while these Conditions and applicable license(s) (if any)
      are in effect and they do not convey any rights of ownership in or to MINNOW, the Website, Games or any of our intellectual property. All right,
      title and interest, including without limitation any copyright, patent,
      trade secret or other intellectual property right in the Website and Games
      will remain our sole property. Any services provided to You under these
      Conditions, and other data or materials that are prepared in the
      performance of such services hereunder, and all right, title and interest
      in the foregoing, will belong to us.
    </p>
    <p class="title">&nbsp;&nbsp;PUBLICITY OF TOURNAMENT RESULTS</p>
    <p class="p">
      13.1 By registering for a tournament at MINNOW, You agree to allow us
      to use your play data within an online live stream, in online
      broadcasting, and any other future publicity that MINNOW may use after
      the completion of the tournament. All MINNOW tournaments are reported
      on in various online media and social media platforms with results
      published that include players’ MINNOW nicknames, finishing positions,
      played-hands and pay-outs in the events. However, we do not publicly use
      players’ real names or personal information unless we have written
      permission from the players to do so. Any final tables of tournaments,
      selected by MINNOW, can be broadcasted on online media, such as the
      Twitch platform, with hole cards open with a delay of 1 (one) hour from
      the actual time of play.
    </p>
    <p class="p">
      13.2. For some specific tournaments, You need to consent that your real
      name will be published if you win any prize from such tournaments. If you
      do not want the publicity of your real name, you should not register for
      such tournaments in MINNOW. For these specific tournaments, You will be
      prompted with a pop-up window during the tournament registration for
      release consent of your information and additional confirmation of your
      real name.
    </p>
    <!-- 14 -->
    <p class="title">&nbsp;&nbsp;INDEMNIFICATION</p>
    <p class="p">
      14. You agree to hold harmless and indemnify us and our subsidiaries,
      affiliates, officers, agents and employees from and against any third
      party claim arising from or in any way related to Your breach of these
      Conditions, or Your violation of any law or the rights of a third party,
      or Your use of the Website and/or Games, including any liability or
      expense arising from all claims, losses, damages (actual or
      consequential), suits, judgments, litigation costs, and attorneys’ fees,
      of every kind and nature. Nothing in these Conditions shall be deemed to
      exclude or limit Your liability in respect of any indemnity given by You
      under these Conditions.
    </p>
    <!-- 15 -->
    <p class="title">&nbsp;&nbsp;LIMITATION OF LIABILITY</p>
    <p class="p">
      15.1 We do not warrant that our operations shall be uninterrupted or
      error-free. We shall provide our Services on an “as is” and “as available”
      basis without warranty of any kind, whether expressed or implied,
      including, but not limited to the implied warranties of merchantability
      and fitness for a particular purpose. To the maximum extent permitted by
      law, we exclude all Conditions, warranties, representations or other terms
      which may apply to the Website and Game, whether express or implied.
    </p>
    <p class="p">
      15.2 Our maximum liability to You for any matter arising out of or in
      connection with Your use of the Website will be limited to the aggregate
      of the amount legitimately in Your account and the return of the amount of
      the stake of any disputed bet.
    </p>
    <p class="p">
      15.3 MINNOW(or any of its group companies officers, directors,
      employees, shareholders or agents) will take all the measures needed in
      order to protect the company against any of the following:
    </p>
    <p class="list">
      &nbsp;&nbsp; • any direct, indirect, special, incidental, consequential
      loss or damages whether exemplary or punitive;
    </p>
    <p class="list">&nbsp;&nbsp; • any loss of income;</p>
    <p class="list">&nbsp;&nbsp; • any loss of direct or indirect profits;</p>
    <p class="list">&nbsp;&nbsp; • any loss of goodwill;</p>
    <p class="list">&nbsp;&nbsp; • any loss of contracts;</p>
    <p class="list">&nbsp;&nbsp; • any loss of use of money;</p>
    <p class="list">
      &nbsp;&nbsp; • any loss or damages arising from or connected in any way to
      business interruption; or
    </p>
    <p class="list">
      &nbsp;&nbsp;in any way whether in tort (including without limitation to
      negligence), contract or otherwise in connection with the Website in any
      way or in connection with the use, any inability to use or the results of
      the use of the Website including but not limited to loss or damages due to
      viruses that may infect Your computer equipment.
    </p>
    <p class="p">
      15.4 We will not be liable for any loss or damage caused by a virus,
      distributed denial-of-service attack, or other technologically harmful
      material that may infect Your computer or mobile equipment, computer
      programs, data or other proprietary material due to Your use of the
      Website or Games or to Your downloading of any content on it, or on any
      website linked to it.
    </p>
    <p class="p">
      15.5 We will not be liable for any loss or damage that You may suffer
      because of any act of God, power failure, trade or labour dispute, act,
      failure or omission of any government or authority, obstruction or failure
      of telecommunication services or networks, access delays or access
      interruption, data non-delivery or data misdelivery, the unauthorised use
      of Your security access details or any other delay or failure caused by a
      third party. In such an event we reserve the right to void any bet or
      cancel or suspend our services arising from such circumstances without
      incurring any liability. Furthermore, we will not be responsible for the
      failure of any equipment, wherever located or administered, or whether
      under our direct control or not, that may prevent the operation of our
      Website or prevent You from being able to contact us.
    </p>
    <p class="p">
      15.6 Nothing in these Conditions shall exclude our liability which we may
      have in respect of fraud or death or personal injury arising from our
      negligence.
    </p>
    <!-- 16 -->
    <p class="title">&nbsp;&nbsp;THIRD-PARTY CONTENT</p>
    <p class="p">
      16. You expressly acknowledge and agree that we shall not be liable to You
      for the content of or use by You of any information or services offered by
      third parties or affiliates advertising, marketing, or otherwise posting
      content through the Website (whether directly or via links to or from
      other sites or resources) nor can we be said to endorse the content of
      such advertisements or information and we make no warranties with respect
      to such content.
    </p>
    <!-- 17 -->
    <p class="title">&nbsp;&nbsp;CUSTOMER COMPLAINTS</p>
    <p class="p">
      17.1 Should there be any claim or dispute relating to bet(s) on MINNOW,
      please contact us at minnow@poker.com and the complaint will be assigned to
      a customer support representative.
    </p>
    <p class="p">
      17.2 An initial response will be sent to customer within 24 hours for
      weekdays and within 72 hours for weekends.
    </p>
    <p class="p">
      17.3 If no subsequent/follow up contact is made by customer within seven
      days, the complaint is considered resolved.
    </p>
    <p class="p">
      17.4 If subsequent/follow up contact is made by customer, the complaint is
      re-examined by the designated customer support representative within 24
      hours for weekdays and within 72 hours for weekends, then either a second
      response is sent or the complaint is escalated to a customer support
      manager according to departmental procedures; and the customer support
      department continues to process customer complaints until they are
      successfully resolved or are deemed in dispute, according to departmental
      procedures.
    </p>
    <!-- 18 -->
    <p class="title">&nbsp;&nbsp;NO SERVICE IN CERTAIN AREAS</p>
    <p class="p">
      18.1 Users shall be prohibited from using the MINNOW service in China
      and the United States. MINNOW strictly prohibits the use of VPN, proxy
      or any alternative methods for a user to play from a prohibited region.
      For example, China, the United States, etc.
    </p>
    <!-- 19 -->
    <p class="title">&nbsp;&nbsp;MISCELLANEOUS</p>
    <p class="p">
      19.1 If, for any reason, a court of competent jurisdiction finds any
      provision of these Conditions, or portion thereof, unenforceable, invalid,
      or unlawful, then the remainder of these Conditions shall continue in full
      force and effect and will be read to give maximum effect to the intention
      of the parties as reflected by its plain language.
    </p>
    <p class="p">
      19.2 You agree that no venture, partnership, employment, or agency
      relationship exists between You and us as a result of these Conditions.
    </p>
    <p class="p">
      19.3 Our failure to exercise any rights under these Conditions shall not
      institute or be deemed a waiver or forfeiture of such right or waiver or
      forfeiture of such rights in the future.
    </p>
    <p class="p">
      19.4 These Conditions constitute the entire understanding between You and
      us relating to the use of the Game.
    </p>
    <p class="title">Disclaimer</p>
    <p class="p">
      We will not be held responsible in any circumstances for any loss or
      damage that may result from the use of the Services, Software or Website.
      We disclaim any warranty for the Services, Software or Website, which are
      provided on an ‘as is’ basis. We will endeavour to supply the Services,
      Software and Website to the highest possible standard and in a safe and
      secure manner, although we make no warranty that their supply will be
      uninterrupted or free from errors. Where any defect is notified to us we
      will make every effort to correct the defect in a timely manner. We will
      not be liable to you where any communication or system is impacted by a
      delay or failure. We reserve the right to suspend, remove or modify the
      Services, either temporarily or permanently, at our sole discretion and
      without any obligation to provide you will notice of such an action. We
      will not be liable for any loss incurred in this circumstance. It is your
      sole responsibility to ensure that you have the legal right to use the
      Services from within the jurisdiction that you reside or access them from.
      It is not our intention that anyone should use the Services from any
      jurisdiction where online gambling is illegal. The availability of the
      Services or Website in any jurisdiction should not be construed as an
      offer, solicitation or invitation by us to use the Services or Website.
      You acknowledge that your participation in the Services is at your sole
      decision and that you accept the full risk of using the Services. You
      further acknowledge that you do not find the Services or Website to be
      offensive, biased or indecent.
    </p>
  </div>
</template>
<script>
export default {
  name: 'articelList',
  props: {},
  data () {
    return {}
  },
  created () {},
  methods: {
    handldBack () {
      console.log(window.uniWebView)
      if (typeof window.uniWebView !== 'undefined') {
        window.uniWebView.postMessage('close') // 发送消息 'close' 到 Unity
      } else {
        console.log('uniWebView is not available.')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  border: 1px solid #000;
  padding-top: 1rem;
  .back-icon {
    position: absolute;
    display: inline-block;
    top: 0.94rem;
    left: 0.72rem;
    width: 0.8rem;
    height: 0.8rem;
  }
  position: relative;
  height: 100%;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  .head {
    font-size: 0.34rem;
    text-align: center;
    margin: 0.28rem 0rem;
    font-weight: 550;
  }
  .head2 {
    font-size: 0.3rem;
    margin: 0.2rem 0rem;
    text-align: center;
  }

  .title {
    font-size: 0.4rem;
    margin: 0.4rem 0rem;
    line-height: 0.48rem;
  }
  .p {
    font-size: 0.3rem;
    margin: 0.2rem 0rem;
    line-height: 0.48rem;
  }
  .list {
    font-size: 0.28rem;
    margin: 0.04rem 0rem;
    line-height: 0.48rem;
  }
  .end p {
    text-align: right;
  }
}
</style>
