import Vue from 'vue'
import VueRouter from 'vue-router'
import Agreement from '@/views/Agreement.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Agreement
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/privacy/index.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/aboutUs/index.vue')
  },
  {
    path: '/articleList',
    name: 'articleList',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/article/index.vue'),
    meta: {
      index: 1
    }
  },
  {
    path: '/articleDetail',
    name: 'articleDetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/article/detail.vue'),
    meta: {
      index: 2
    }
  },
  {
    path: '/articleDetail',
    name: 'articleDetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/article/detail.vue'),
    meta: {
      index: 2
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
